import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TeamMember from "../components/team/teamMember"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import {
  Container,
  Headline,
  Subline,
} from "../components/layout/layoutComponents"
import styled from "styled-components"
import top from "../images/top.png"
import bottom from "../images/bottom.png"

const ContactH1 = styled.h1`
  margin: 0 auto;
  padding-top: 130px;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 6px;
  text-transform: uppercase;
  @media (min-width: 516px) {
    font-size: 46px;
    line-height: 58px;
  }
  @media (min-width: 688px) {
    font-size: 70px;
    line-height: 84px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 15px;
    font-size: 100px;
    line-height: 132px;
  }
`

const ContactH2 = styled.h2`
  margin: 0 auto;
  max-width: 715px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  @media (min-width: 516px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (min-width: 720px) {
    font-size: 30px;
    line-height: 39px;
  }
`

const ContactForm = styled.form`
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  max-width: 700px;
`
const ContactInput = styled.input`
  background: transparent;
  border: 1px solid #fff;
  padding: 10px;
  margin: 15px 0;
  height: 45px;
  ::placeholder {
    font-size: 20px;
    color: #fff;
    font-weight: 300;
    padding: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`
const ContactText = styled.textarea`
  background: transparent;
  border: 1px solid #fff;
  padding: 10px;
  margin: 15px 0;
  height: 310px;
  ::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 300;
    padding: 10px;
  }
`
const ContactAgree = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
  justify-content: space-between;
  color: #fff;
  @media (min-width: 720px) {
    font-size: 20px;
    line-height: 28px;
  }
  label {
    width: 85%;
    @media (min-width: 720px) {
      width: 95%;
    }
  }
`
const TeamSection = styled.div`
  margin: 70px auto 70px;
  max-width: 1140px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 55px 0;
  display: flex;
  justify-content: space-around;
`
const MemberContainer = styled.div`
  z-index: 3000;
  max-width: 400px;
  padding: 0 15px;
  margin: 35px;
  display: flex;
  flex-direction: column;
  :last-child {
    align-self: flex-end;
  }
  img {
    max-width: 400px;
    width: auto;
  }
`
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const Top = styled.div`
  display: none;
  @media (min-width: 1205px) {
    display: block;
    position: absolute;
    top: -88px;
    right: -69px;
    width: 230px;
    height: 230px;
    background: url(${top});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    top: -110px;
    right: -120px;
    width: 400px;
    height: 400px;
  }
`
const ContactButton = styled.button`
  justify-content: center;
  display: flex;
  justify-self: flex-end;
  z-index: 5000;
  margin-top: 10px;
  min-width: 115px;
  padding: 0;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  box-shadow: 0px 3px 6px #00000029;
  &:hover {
    cursor: pointer;
  }
`

const Bottom = styled.div`
  display: none;
  @media (min-width: 1205px) {
    display: block;
    position: absolute;
    bottom: -476px;
    left: -426px;
    width: 900px;
    height: 1100px;
    background: url(${bottom});
    background-size: cover;
  }
  @media (min-width: 1410px) {
    bottom: -668px;
    left: -530px;
    width: 1400px;
    height: 1420px;
  }
`

// function encode(data) {
//   return Object.keys(data)
//     .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        craftHomepageHomepageEntry {
          team {
            ... on Craft_team_team_Entry {
              teamName
              teamRole
              teamIntro
              phoneNumber
              email
              image {
                url
              }
            }
          }
        }
      }
    `}
    render={({ craftHomepageHomepageEntry }) => (
      <Layout>
        <SEO title="Contact" />
        <div style={{ backgroundColor: `#F28E00` }}>
          <Container style={{ textAlign: `center` }}>
            <ContactH1>Contacting Avance</ContactH1>
            <ContactH2>
              Questions, suggestions, feedback? We would be delighted to hear
              from you
            </ContactH2>
            <ContactForm
              method="POST"
              data-netlify="true"
              name="contact-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact-form" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <ContactInput
                name="name"
                type="text"
                id="input-name"
                placeholder="Name"
                required
              />
              <ContactInput
                name="email"
                type="email"
                id="input-email"
                placeholder="E-mail"
                required
              />
              <ContactText
                name="message"
                type="text"
                id="input-message"
                placeholder="Message"
                required
              />
              <ContactAgree>
                <ContactInput
                  type="checkbox"
                  id="gdpr"
                  name="gdpr"
                  value="gdpr"
                  required
                />
                <input type="hidden" name="contact-form" value="contact" />
                <label for="gdpr">
                  I have read the Privacy Policy note. I agree that my details
                  and data will be collected and stored electronically to answer
                  my request. Note: You can revoke your consent at any time.
                </label>
              </ContactAgree>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  justifyContent: `flex-end`,
                }}
              >
                <ContactButton type="submit" value="Submit">
                  Send
                </ContactButton>
              </div>
            </ContactForm>
          </Container>
        </div>
        <Wrapper>
          <Container>
            <TeamSection>
              <div
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  textAlign: `center`,
                }}
              >
                <Headline>we are moving forward</Headline>
                <Subline>our team</Subline>
              </div>
              {craftHomepageHomepageEntry.team.map(member => (
                <MemberContainer>
                  <TeamMember
                    src={member.image[0].url}
                    alt={member.teamName}
                    name={member.teamName}
                    role={member.teamRole}
                    mail={member.email}
                    phone={member.phoneNumber}
                  />
                </MemberContainer>
              ))}
              <Bottom />
            </TeamSection>
          </Container>
          <Top />
        </Wrapper>
      </Layout>
    )}
  />
)

export default Contact
